<template>
  <div class="absolute left-0 right-0 top-0 z-1 p-4">
    <div class="flex items-center justify-between">
      <niceone-image :src="$publicPath('/images/video/close.svg')" width="26" @click="emit('close')"/>
      <div class="flex items-center gap-1">
        <van-badge :content="count" :max="99" :position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
                   class="mx-3" color="#997adb" @click="showCart = true">
          <niceone-image :src="$publicPath('/images/video/cart.svg')" width="18"/>
        </van-badge>
        <niceone-image :src="$publicPath('/images/video/share.svg')" width="26" @click="shareVideo"/>
      </div>
    </div>
    <div class="mt-1">
      <div class="text-white text-lg font-bold">{{ item?.title }}</div>
      <div class="text-white font-bold text-xs">{{ item?.description }}</div>
    </div>
  </div>
</template>

<script setup>
import {useCartStore} from "@/store/cart";
import {useShareLink} from "@/composables/useShareLink";

const props = defineProps({
  item: {
    type: Object,
    default: () => {
    }
  },
  isPlaying: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['close', 'toggleVideo'])

const cartStore = useCartStore()
const {count, showCart} = storeToRefs(cartStore)
const {share} = useShareLink()

const shareVideo = () => {
  props.isPlaying && emit('toggleVideo')
  const data = {
    title: props?.item?.title,
    text: props?.item?.video_deepLink,
  }
  share(data.text, data.title)
}
</script>
