export const PAYMENT_TYPES = {
    CHECKOUT_PAYMENT: 'checkout_payment',
    APPLE_PAY: 'checkout_applepay',
    TAMARA: 'tamara',
    COD: "cod",
    FREE_CHECKOUT: 'free_checkout',
    TABBY: 'tabby',
    STC_PAY: 'stc'
}

export const PAYMENT_TYPE_ICONS = {
    [PAYMENT_TYPES.CHECKOUT_PAYMENT]: [
        {
            src: '/images/payment/mada.png',
            width: '40'
        },
        {
            src: '/images/payment/visa.png',
            width: '40'
        },
        {
            src: '/images/payment/master.png',
            width: '40'
        },
        {
            src: '/images/payment/express.svg',
            width: '35'
        },

    ],
    [PAYMENT_TYPES.APPLE_PAY]: [
        {
            src: '/images/payment/apple-pay.png',
            width: '40'
        }
    ],
    [PAYMENT_TYPES.TAMARA]: [
        {
            language: true,
            src: '/images/payment/tamara-ar.png',
            src_ar: '/images/payment/tamara-ar.png',
            src_en: '/images/payment/tamara-en.png',
            width: '75'
        }
    ],
    [PAYMENT_TYPES.TABBY]: [
        {
            src: '/images/payment/tabby.svg',
            width: '75'
        }
    ],
    [PAYMENT_TYPES.COD]: [
        {
            src: '/images/payment/cash.png',
            width: '24',
        },
        {
            src: '/images/payment/service.png',
            width: '20'
        }
    ],
    [PAYMENT_TYPES.STC_PAY]: [
        {
            src: '/images/payment/stc.svg',
            width: '65',
        }
    ]
}

export const TAMARA_PAYMENT_TYPES = {
    PAY_BY_INSTALMENTS: 'PAY_BY_INSTALMENTS',
    PAY_BY_LATER: 'PAY_BY_LATER'
}