import {useMutation, useQuery, useQueryClient} from '@tanstack/vue-query';

export function getWebHomeData() {

    const queryKey = ['web-home'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/product_admin/web_mainScreen_v2").then(response => response?.data || {})
        }
    );
}


export function getMobileHomeData() {

    const queryKey = ['mobile-home'];

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios.get("?route=rest/product_admin/app_mainScreen_v2").then(response => response?.data?.tabs || {})
        }
    );
}

export function likedVideoMutation() {
    return useMutation(({video_id = '', is_liked = false}) => {
        let params = {video_id, is_liked}
        return useNuxtApp().$axios.post(`?route=rest/product_admin/app_home_video_like`, params).then((response) => response?.data || {});
    });
}


export function getDesktopProductList(params, options) {

    const {componentId, targetId, sort} = params

    const {enabled = true} = options

    const queryKey = ["product-slider", componentId, targetId, sort]

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/product_admin/component_v2", {params}).then(response => response?.data || {})
        },
        {
            enabled
        }
    );
}

export function getMobileTabsData() {
    const queryClient = useQueryClient();
    return useMutation(
        ({tab_id = ""}) => {
            return useNuxtApp().$axios("?route=rest/product_admin/tabComponents", {params: {tab_id}})
        },
        {
            onSuccess: (({data}, variables) => {

                // get home tabs data
                const tabs = queryClient.getQueryData(['mobile-home'])

                const tabsData = [...tabs];
                const tabIndex = tabsData.findIndex(item => item.tab_id == variables.tab_id)
                if (tabIndex !== -1) {
                    tabsData[tabIndex] = {
                        ...tabsData[tabIndex],
                        components: data.components
                    }

                    // update home tabs data
                    queryClient.setQueryData(['mobile-home'], tabsData)
                }
            })
        }
    );
}

export function getMobileProductList(params, options) {

    const {componentId, targetId, sort} = params

    const {enabled = true} = options

    const queryKey = ["product-slider", componentId, targetId, sort]

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/product_admin/app_component_v2", {params}).then(response => response?.data?.products?.length ? response?.data?.products : response?.data?.products?.products || [])
        },
        {
            enabled
        }
    );
}

export function getProductById(id) {

    const queryKey = ["product-details", id]

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios(`?route=rest/product_admin/products&id=${id}`).then(response => response?.data || {})
        }
    );
}

export function getReviewsByProductId() {
    return useMutation(({id = '', offset = 1, limit = 3, sort = ''} = {}) => {
        return useNuxtApp().$axios.get(`?route=rest/reviews/product_reviews`, {
            params: {id, offset, limit, sort}
        });
    });
}

export function getRecommendedProducts(params, options) {

    const {category = "", productId = ""} = params

    const {enabled = false} = options

    const queryKey = ["recommended-products-slider", productId, category]

    return useQuery(
        queryKey,
        () => {
            return useNuxtApp().$axios("?route=rest/product_admin/getProductSection1Content", {
                params: {
                    category,
                    product_id: productId
                }
            }).then(response => response?.data || {})
        },
        {
            enabled
        }
    );
}

export function getRecommendedProductsMutation() {
    return useMutation(({productId = '', category = '', page = 1, limit = 3} = {}) => {
        return useNuxtApp().$axios.get(`?route=rest/product_admin/getProductSection1Content`, {
                params: {product_id: productId, category, page, limit}
            }
        );
    });
}

export function getVideoDetailsByIdMutation() {
    return useMutation(
        (video_id = "") => {
            return useNuxtApp().$axios("?route=rest/product_admin/app_home_videos", {params: {video_id}})
        })
}