<template>
  <nuxt-link :to="getProductUrl"
             class="min-w-[118px] max-w-[118px] rounded-xl bg-white relative max-h-[187px] overflow-hidden"
             @click="onProductClicked">
    <div :class="{ 'opacity-50': outOfStock && !hideOverlay }" :style="`padding: ${padding}px`" class="p-3 rounded-xl">
      <div class="relative">
        <template v-if="!hideTags && !outOfStock">
          <tag-new v-if="product.is_new"/>
          <tag-dynamic :tag="product.tag"/>
        </template>
        <slot name="outOfStock">
          <template v-if="outOfStock">
            <tag-out-of-stock/>
          </template>
        </slot>
        <slot name="image"></slot>
        <!-- Product cart button -->
        <slot name="cart-icon-button"></slot>
      </div>
      <slot :title="product.name" name="product-name"></slot>
      <slot name="price"></slot>
    </div>
  </nuxt-link>
</template>

<script setup>
import TagNew from "@/components/tags/New";
import TagDynamic from "@/components/tags/Dynamic";
import TagOutOfStock from "@/components/tags/OutOfStock"
import {TRACKERS} from "@/constants/events";
import {track} from "@/composables/useTrackEvent";

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
  padding: {
    type: Number,
    default: 16
  },
  brandFontSize: {
    type: String,
    default: '12'
  },
  hideTags: {
    type: Boolean,
    default: false
  },
  hideCartBtn: {
    type: Boolean,
    default: false
  },
  hideOverlay: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object,
    default: {}
  }
})
const route = useRoute()
const emit = defineEmits(['productClicked', 'close'])
const outOfStock = computed(() => !props?.product?.has_stock)

const getProductUrl = computed(() => {
  if (props.product.seo_url_en || props.product.seo_url)
    return useNuxtApp().$routerUrl(props.product.seo_url_en ?
        props.product.seo_url_en : props.product.seo_url)
  return `${props.product.name
      .toLowerCase()
      .split(' ')
      .join('-')}-n${props.product?.id || props.product?.product_id}`
})

const onProductClicked = () => {
  track(TRACKERS.PRODUCT_CLICKED, {product: props.product, sourceData: props.data})
  if (route.name?.split('_')[0] === 'product-details') {
    track(TRACKERS.RECOMMENDED_PRODUCT, {product: props.product, sourceData: props.data})
  }
  emit('productClicked')
  emit('close')
}
</script>