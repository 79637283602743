<template>
  <van-popup v-model:show="model" :style="{ height: '100%' }" position="top" @opened="onVideoOpened">
    <slot>
      <video-details v-if="model" :video-id="selectedVideoId" @close="model = false"/>
    </slot>
  </van-popup>
</template>

<script setup>
import VideoDetails from "@/components/dynamic_components/video-slider/VideoDetails"
import {track} from "@/composables/useTrackEvent";
import {TRACKERS, EVENTS} from "@/constants/events";
import {useEventBus} from "@vueuse/core/index";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})
const model = defineModel()
const route = useRoute()
const {on: openVideoDailog} = useEventBus(EVENTS.VIDEO_DIALOG);

onMounted(() => route?.query?.videoid && onSelectVideo({id: route?.query?.videoid}))
openVideoDailog((id) => onSelectVideo({id}))

const selectedVideoId = ref(null)
const onSelectVideo = ({id = 0}) => {
  selectedVideoId.value = id
  model.value = true
}

const onVideoOpened = () => {
  track(TRACKERS.OPEN_VIDEO_PAGE, selectedVideoId.value)
}
</script>
