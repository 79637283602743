<template>
  <div class="space-y-3 min-h-23 px-4">
    <div class="flex-center flex-col relative">
      <div class="min-h-7">
        <niceone-image :src="$publicPath('/images/video/heart.svg')" v-if="!isVideoLiked" width="25" class="mt-1"
                       @click="toggleLikeVideo"/>
        <niceone-image :src="$publicPath('/images/video/fill-heart.svg')" v-else width="26"
                       @click="toggleLikeVideo"/>
      </div>
      <span class="text-white font-niceone-medium block">{{ counter }}</span>
    </div>
    <div :class="[$i18n.locale === 'en' ?? 'flip-icon-ltr']">
      <niceone-image :src="$publicPath('/images/video/unmute.svg')" v-if="muted" width="26" @click="toggleSound"/>
      <niceone-image :src="$publicPath('/images/video/mute.svg')" v-else width="26" @click="toggleSound"/>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import {likedVideoMutation} from "@/plugins/API/home";
import {EVENTS} from "@/constants/events";
import {useHomeStore} from "@/store/home";

const {emit: emitAuth} = useEventBus(EVENTS.AUTH_DIALOG);
const props = defineProps({
  item: {
    type: Object,
    default: () => {
    }
  },
  isPlaying: {
    type: Boolean,
    default: false
  },
})
const emit = defineEmits(['mute', 'toggleVideo'])
const homeStore = useHomeStore()
const {muted} = storeToRefs(homeStore)

const toggleSound = () => muted.value = !muted.value

const authStore = useAuthStore();
const {isLoggedIn} = storeToRefs(authStore);
const isVideoLiked = ref(props?.item?.is_liked_by_user || false)
const counter = ref(props?.item?.like_count)

const {mutateAsync} = likedVideoMutation()

const toggleLikeVideo = () => {
  if (!isLoggedIn.value) {
    props.isPlaying && emit('toggleVideo')
    emitAuth({redirection: false})
    return
  }
  mutateAsync({video_id: props?.item?.id, is_liked: !isVideoLiked.value}).then(() => {
    isVideoLiked.value = !isVideoLiked.value
    isVideoLiked.value ? counter.value++ : counter.value--
  })
}
</script>
