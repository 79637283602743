<template>
  <client-only>
    <full-page-loader :loading="isLoading"/>
    <div v-if="!isLoading" class="full-height">
      <swiper
          :modules="[SwiperNavigation]"
          class="h-full"
          direction="vertical"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
      >
        <swiper-slide v-for="(item, index) in videos" :key="`video-item-${index}`"
                      v-slot="{ isActive, isNext, isPrev }">

          <template v-if="isActive || isNext || isPrev">
            <video-header :is-playing="isPlaying" :item="item" @close="emit('close')" @toggleVideo="toggleVideo"/>

            <video v-if="activeIndex === index" ref="videoRef" :poster="item?.thumbnail_url"
                   autoplay
                   class="w-full h-full absolute object-cover"
                   muted
                   playsinline
                   @ended="playOtherVideo"
                   @loadedmetadata="onVideoLoaded"
            >
              <source
                  :src="item?.video_url"
                  type="video/mp4"/>
            </video>
            <background-image :src="item?.thumbnail_url" class="w-full h-full"/>
            <div class="cursor-pointer absolute top-0 left-0 right-0 bottom-0 flex-center bg-[#00000040]"
                 @click="toggleVideo">
              <niceone-image v-if="!isPlaying" :src="$publicPath('/images/video/play.svg')" width="50"
                             @click.stop.prevent="toggleVideo"/>
              <van-loading v-if="videoLoading" color="#997adb" size="56"/>
            </div>
            <niceone-image v-if="!isDemoHandHide"
                           :class="[$i18n.locale === 'ar' ? 'left-[60%]':'right-[60%] flip-icon-ltr']"
                           :src="$publicPath('/images/video/hand.gif')"
                           class="absolute top-[50%]"
                           width="200"/>
            <video-panel v-if="!item?.products?.length" :class="[$i18n.locale === 'ar' ? 'left-0':'right-0']"
                         :is-playing="isPlaying"
                         :item="item"
                         class="absolute top-[62vh]" @toggleVideo="toggleVideo"/>
            <div class="absolute left-0 right-0 bottom-0 flex flex-col items-end">
              <video-panel v-if="item?.products?.length" :is-playing="isPlaying" :item="item"
                           @toggleVideo="toggleVideo"/>
              <video-products :products="item?.products" class="pb-4 mt-6 max-w-full" @close="emit('close')"/>
            </div>
          </template>
        </swiper-slide>
      </swiper>
    </div>
  </client-only>
</template>

<script setup>
import {useCartStore} from "@/store/cart";
import {useHomeStore} from "@/store/home";
import {storeToRefs} from "pinia";
import {onLongPress} from '@vueuse/core'
import {getVideoDetailsByIdMutation} from "@/plugins/API/home";
import VideoProducts from "@/components/dynamic_components/video-slider/VideoProducts"
import BackgroundImage from "~/components/common/BackgroundImage.vue";
import VideoHeader from '@/components/dynamic_components/video-slider/VideoHeader'
import VideoPanel from '@/components/dynamic_components/video-slider/VideoPanel'
import {COOKIE_KEYS} from "@/constants/cookie";

const props = defineProps({
  videoId: {
    type: [Number, String],
    default: 0
  }
})

const emit = defineEmits(['close'])

const videos = ref([])
const {isLoading, mutateAsync} = getVideoDetailsByIdMutation()
const homeStore = useHomeStore()
const {muted} = storeToRefs(homeStore)

const videoIndex = computed(() => videos.value.findIndex(item => item.id === props.videoId) || 0)
const slideToActiveVideo = () => {
  swiperRef.value?.slideTo(videoIndex.value)
}

mutateAsync(props?.videoId).then(({data}) => {
  videos.value = data?.videos || []
  slideToActiveVideo()
})

const swiperRef = ref(null);
const onSwiper = (swiper) => {
  swiperRef.value = swiper;
};

const activeIndex = ref(0)
const onSlideChange = (e) => {
  activeIndex.value = e?.activeIndex;
  hideDemoHand()
  // Reset
  reset()
}

const videoLoading = ref(true)

const cartStore = useCartStore()
const {showCart} = storeToRefs(cartStore)

const videoRef = ref(null);
const isPlaying = ref(true)

const toggleVideo = () => {

  if (videoRef.value[0]?.paused || videoRef.value[0]?.ended) {
    playVideo()
    isPlaying.value = true
  } else {
    videoRef.value[0]?.pause();
    isPlaying.value = false
  }
}

const longPressRef = ref(null)
onUnmounted(() => {
  activeIndex.value = 0
})
onLongPress(
    longPressRef,
    toggleVideo
)

const onVideoLoaded = () => {
  videoLoading.value = false

  playVideo();
}

const playVideo = async () => {
  try {
    await videoRef.value[0]?.play();
  } catch (err) {
    console.log('err: ', err)
  }
}

const playOtherVideo = () => {
  swiperRef.value?.slideTo(activeIndex.value + 1)

  reset()
}

const reset = () => {
  videoLoading.value = true
  isPlaying.value = true
  // videoRef.value?.[0]?.play();
}

watch(showCart, () => {
  toggleVideo()
})

const isDemoHandHide = useCookie(COOKIE_KEYS.VIDEO_SLIDER)
const hideDemoHand = () => isDemoHandHide.value = true
</script>