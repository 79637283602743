export const useHomeStore = defineStore('home', () => {

    const tabId = ref(null)
    const persistentTab = ref(null)
    const muted = ref(false)
    return {
        tabId,
        persistentTab,
        muted
    }
})