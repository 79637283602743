<template>
  <nuxt-link :to="getProductUrl" class="min-w-[160px] max-w-[450px] rounded-md bg-white relative"
             @click="onProductClicked">
    <slot name="badge"></slot>

    <!-- Product overlay for Out of stock-->
    <div v-if="outOfStock && !hideOverlay"
         class="absolute bg-white bg-opacity-50 z-1 left-0 right-0 top-0 bottom-0"></div>
    <div :style="`padding: ${padding}px`" class="p-3 rounded-sm">
      <slot name="divider"></slot>
      <div class="relative">

        <!-- Product tags and Favourite icon -->
        <div class="absolute left-0 right-0 flex justify-between z-1">
          <!--          <loyalty-product-tag v-if="product?.loyalty" :loyalty="product?.loyalty"/>-->
          <flash-sale-timer v-if="!hideTags" :product="product"/>
          <favourite-icon v-if="!hideFavBtn" :id="product[idKey]" :product="product"/>
        </div>

        <!-- Product cart button -->
        <template v-if="!hideCartBtn">
          <cart-icon-button :id-key="idKey" :link="getProductUrl" :product="product" class="z-1"/>
        </template>

        <!-- Product Image with slot -->
        <slot name="image">
          <div class="relative flex-center h-[150px]">
            <niceone-image v-if="product.thumb" :src="product.thumb" class="w-[130px]"/>
          </div>
        </slot>

        <!-- Reviews -->
        <div v-if="!hideReviews" class="min-h-[16px]">
          <div v-if="product?.rating" class="flex items-center pb-1">
            <div class="bg-light-grey rounded-full flex items-center px-1 h-4">
              <span class="text-color-[#505050] text-xs">{{ product.rating?.toFixed(1) }}</span>
              <van-icon color="#f5c523" name="star" size="12"/>
            </div>
            <span class="text-[10px] text-color-[#CBCBCB] ms-1">({{ product?.total_reviews }})</span>
          </div>
        </div>
      </div>

      <!--  Manufacture and product name -->
      <div class="text-xs text-boulder font-bold truncate">{{ product.manufacturer }}</div>
      <div class="min-h-[40px]">
        <div class="van-multi-ellipsis--l2 text-xs leading-5">
          {{ product.name }}
        </div>
      </div>

      <!-- Product price with Slot -->
      <slot name="price">
        <price :increase-font-by="2" :product="product"/>
      </slot>
    </div>
  </nuxt-link>
</template>

<script setup>
import Price from "@/components/product/Price"
import CartIconButton from "@/components/product/ProductIconCartButton";
import {TRACKERS} from "@/constants/events";
import FavouriteIcon from "@/components/product/FavouriteIcon";
import FlashSaleTimer from "@/components/tags/FlashSaleTimer";
import {track} from "@/composables/useTrackEvent";

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
  padding: {
    type: Number,
    default: 10
  },
  hideFavBtn: {
    type: Boolean,
    default: false
  },
  hideCartBtn: {
    type: Boolean,
    default: false
  },
  hideReviews: {
    type: Boolean,
    default: false
  },
  hideTags: {
    type: Boolean,
    default: false
  },
  hideOverlay: {
    type: Boolean,
    default: false
  },
  idKey: {
    type: String,
    default: 'id'
  },
  data: {
    type: Object,
    default: {}
  }
})
const route = useRoute()
const emit = defineEmits(['productClicked'])
const outOfStock = computed(() => !props?.product?.has_stock)

const getProductUrl = computed(() => {
  const {seo_url_en = '', seo_url = '', name, id = '', product_id = '', queryID = ''} = props.product;

  const link = seo_url_en || seo_url || `${name.toLowerCase().split(' ').join('-')}-n${id || product_id}`;
  const queryString = queryID ? `?queryID=${queryID}` : '';

  return useNuxtApp().$routerUrl(`${link}${queryString}`);
});

const onProductClicked = () => {
  track(TRACKERS.PRODUCT_CLICKED, {product: props.product, sourceData: props.data})
  if (route.name?.split('_')[0] === 'product-details') {
    track(TRACKERS.RECOMMENDED_PRODUCT, {product: props.product, sourceData: props.data})
  }
  emit('productClicked')
}
</script>